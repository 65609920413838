<template>
<section class="agency-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-6">
                        <!-- <h2 class="content-header-title float-left mb-0">Submit List</h2> -->
                        <h5 class="content-header-title float-left mt-50">Claim List</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake Lists</router-link></li>
                                <li class="breadcrumb-item" ><router-link to="/submit-to-university">{{ cn(intakeItem, 'name', 'Loading...') }}</router-link></li>
                                <!-- <li class="breadcrumb-item active"><router-link :to="`/gateway/intake-wise-agent-list/${$route.params.intake_id}`">{{ agentStudentListItem.list_code }}</router-link></li> -->
                                <li class="breadcrumb-item active">All students</li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-6 text-right" v-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published'">
                        <span class="alert p-1" :class="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' ? 'alert-success' : ' alert-danger'">
                            <span v-if="cn(intakeItem, 'end_date', null)" v-html="'Submission Deadline: '+ dDate(intakeItem.end_date)"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

            <div class="content-body">
                <section class="app-user-list">
                <section class="modern-horizontal-wizard">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">
                            <!-- <div class="step" :class="{ active: isActive('zero') }">
                                <button  @click="setActive('zero')" type="button" class="step-trigger" aria-selected="false">
                                    <span class="bs-stepper-box">1</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Student List <span class="badge badge-primary badge-pill">
                                                <span v-html="ss_counter.total_student"></span>
                                            </span>
                                        </span> 
                                    </span>
                                </button>
                            </div>
                            <div class="line"  v-if="ss_counter.total_submitted">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div> -->
                            <div class="step" :class="{ active: isActive('one') }" v-if="ss_counter.total_submitted">
                                <button  @click="setActive('one')" type="button" class="step-trigger" aria-selected="false">
                                    <!-- <span class="bs-stepper-box">1</span> -->
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Claim List <span class="badge badge-primary badge-pill">
                                                <!-- <span v-html="ss_counter.total_submitted"></span> / <span v-html="ss_counter.total_student"></span> -->
                                                <span v-html="ss_counter.total_submitted"></span>
                                            </span>
                                        </span> 
                                    </span>
                                </button>
                            </div>
                            <div class="line" v-if="ss_counter.total_confirmed">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <div class="step" :class="{ active: isActive('two') }" v-if="ss_counter.total_confirmed">
                                <button @click="setActive('two')" type="button" class="step-trigger" aria-selected="false">
                                    <!-- <span class="bs-stepper-box">2</span> -->
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Confirmed List <span class="badge badge-primary badge-pill" v-html="ss_counter.total_confirmed">
                                        </span></span> 
                                    </span>
                                </button>
                            </div>

                            <div class="line" v-if="ss_counter.total_disputed && ss_counter.total_confirmed">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <div class="step" :class="{ active: isActive('three') }" v-if="ss_counter.total_disputed && ss_counter.total_confirmed">
                                <button @click="setActive('three')" type="button" class="step-trigger" aria-selected="false">
                                    <!-- <span class="bs-stepper-box">3</span> -->
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Disputed List <span class="badge badge-primary badge-pill" v-html="ss_counter.total_disputed">
                                        </span></span> 
                                    </span>
                                </button>
                            </div>
                            <div class="line" v-if="ss_counter.total_declined && ss_counter.total_confirmed">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>

                            <div class="step" :class="{ active: isActive('four') }" v-if="ss_counter.total_declined">
                                <button @click="setActive('four')" type="button" class="step-trigger" aria-selected="false">
                                    <!-- <span class="bs-stepper-box">{{ ss_counter.total_disputed > 0 ? 4 : 3 }}</span> -->
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Declined List <span class="badge badge-primary badge-pill" v-html="ss_counter.total_declined">
                                        </span></span> 
                                    </span>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="bs-stepper-content"> -->
                        <div class="">
                            <!-- <div id="submitted" class="content wait_me_list_data" :class="{ active: isActive('zero') }">
                                <submitted-unsubmitted-student-list ref="submitted_unsubmitted_list" :ss_counter="ss_counter" :list_info="agentStudentListItem"></submitted-unsubmitted-student-list>
                            </div> -->
                            <div id="submitted" v-if="activeItem == 'one'" class="content wait_me_list_data" :class="{ active: isActive('one') }">
                                <submitted-student-list ref="submitted_list" :ss_counter="ss_counter" :list_info="agentStudentListItem"></submitted-student-list>
                            </div>

                            <div id="confirmed" v-if="activeItem == 'two'" class="content wait_me_list_data" :class="{ active: isActive('two') }"> 
                                <confirmed-student-list ref="confirmed_list" :ss_counter="ss_counter" :list_info="agentStudentListItem" v-on:updateAgentStudentList="getAgentStudentListCodeByListId()"></confirmed-student-list>
                            </div>

                            <div id="disputed" v-if="activeItem == 'three'" class="content wait_me_list_data" :class="{ active: isActive('three') }"> 
                                <disputed-student-list ref="disputed_list" :ss_counter="ss_counter" :list_info="agentStudentListItem"></disputed-student-list>
                            </div>

                            <div id="declined" v-if="activeItem == 'four'" class="content wait_me_list_data" :class="{ active: isActive('four') }"> 
                                <declined-student-list ref="declined_list" :ss_counter="ss_counter" :list_info="agentStudentListItem"></declined-student-list>
                            </div>

                        </div>
                    </div>
                </section>
                </section>
            </div>

    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
// import SubmittedUnsubmittedStudentList from './SubmittedUnsubmittedStudentList.vue';
import SubmittedStudentList from './SubmittedStudentList.vue';
import ConfirmedStudentList from './ConfirmedStudentList.vue';
import DisputedStudentList from './DisputedStudentList.vue';
import DeclinedStudentList from './DeclinedStudentList.vue';

export default{
    name: 'IntakeWiseStudentList',
    metaInfo: {
        titleTemplate: 'University Of Sheffield | Intake wise students'
    },
    components:{
        MainLayout,
        // SubmittedUnsubmittedStudentList,
        SubmittedStudentList,
        ConfirmedStudentList,
        DisputedStudentList,
        DeclinedStudentList
    },
    data:()=>{
        return {
            list_id: 4,
            intake_id: null,
            activeItem: 'one',
            userTreeselectList: [],
            agentStudentListItem: {},
            intakeItem: {},
            ss_counter:{
                total_student: 0,
                total_submitted: 0,
                total_confirmed: 0,
                total_declined: 0,
                total_disputed: 0,
            }
        };
    },
    methods:{
        setActive: function (menuItem) {
            this.activeItem = menuItem // no need for Vue.set()
            if (menuItem == 'zero') {
                this.$refs.submitted_unsubmitted_list.getListData();
            } else if (menuItem == 'one') {
                this.$refs.submitted_list.getListData();
            } else if (menuItem == 'two') {
                this.$refs.confirmed_list.getListData();
            } else if (menuItem == 'three') {
                this.$refs.disputed_list.getListData();
            } else if (menuItem == 'four') {
                this.$refs.declined_list.getListData();
            }
            this.get_count_student_by_statuses();
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        gotoBulkVerficationUploadPage: function () {
            this.$router.push({ name: 'BulkVerficationUpload', params: { id: this.$route.params.intake_id } })
        },
        goto_agent_wise_bulk_students: function () {
            this.$router.push({ name: 'AgencyWiseBulkStudent', params: { hash: this.hash_id(this.list_id) } })
        },
        getAgentStudentListCodeByListId: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_agent_wise_student_list_item_by_list_id'), params);
                this.agentStudentListItem = res.data.item

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            
            // get intake item by intake id
            try {
                const params = Object.assign({}, {intake_id: this.intake_id})
                var intake_res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                ref.intakeItem = intake_res.data.intake

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        get_count_student_by_statuses: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/ajax/count_student_by_statuses');

            try {
                var res = await jq.get(url, { list_id: this.list_id, intake_id: this.intake_id });
                ref.ss_counter = res.data.ss_counter;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

        },
    
    },
    computed: {
        
    },
    created: function(){
        this.setup_urls();

        if (!this.$route.params.list_id) {
            this.$router.push({ name: 'SubmitToUniversity' })
        } else {
            this.list_id = this.hash_id(this.$route.params.list_id, false)[0]
            if (!this.list_id) {
                this.$router.push({ name: 'SubmitToUniversity', params: { id: this.list_id } })
            }
            this.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
            if (!this.intake_id) {
                this.$router.push({ name: 'SubmitToUniversity', params: { id: this.list_id } })
            }
        }

        if (this.$route.params.active_tab) {
            this.activeItem = this.$route.params.active_tab
        }
        const query = this.$route.query
        if (query) {
            // console.log('this.$route.query', this.$route.query)
            if (this.$route.query.tab) {
                this.activeItem = this.$route.query.tab
            }
        }
    },
    mounted: function(){
        feather.replace();
        this.getAgentStudentListCodeByListId()
        this.get_count_student_by_statuses();
        
    }
}
</script>
<style>
.bs-stepper .bs-stepper-content {
    padding: 0rem 0rem !important;
}
.card {
    margin-bottom: 0rem!important;
}

.active > button {
    background: #9bffab !important;
    /* background: #cecece !important;; */
    padding: 10px !important;
    border-radius: 8px !important;
}

.agency-list .bs-stepper.wizard-modern .bs-stepper-header {
    border: none;
    background: #fff;
    border-top-left-radius: 0.357rem;
    border-top-right-radius: 0.357rem;
}
.agency-list .app-user-list .agency-list-card{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step {
    padding: 6px;
    border: 1px solid #e7e7eb;
    border-radius: 0.357rem;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step.active > button {
    background: #e6e2ff !important;
    border-radius: 0.357rem !important;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
    margin-top: 0;
    margin-left: 0;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step .step-trigger {
    padding: 8px 10px;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title .badge {
    border-radius: .25rem;
    background: #e6e2ff !important;
    color: #472884;
    margin-left: 8px;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
    color: rgb(105 102 175) !important;
}
.agency-list .app-user-list .bs-stepper .bs-stepper-header .step.active > button .bs-stepper-label .bs-stepper-title .badge{
    background: #6966af !important;
    color: #fff;
}
.agency-list .app-user-list .bs-stepper .agency-list-card .card_head{
    padding: 0 0 21px;
}
.agency-list .app-user-list .bs-stepper .agency-list-card .card_head .light_text {
    color: #868e96;
    font-size: 12px;
}
.agency-list .app-user-list .bs-stepper .cust_btn {
    border-radius: 0.357rem !important;
}
.agency-list .app-user-list .bs-stepper .cust_btn.btn-primary {
    border-color: rgb(105 102 175) !important;
    background: #6966af !important;
}
.agency-list .app-user-list .bs-stepper .cust_btn.btn-secondary {
    background: #d9d8dd !important;
    color: #6966af !important;
    border-color: #b2b0df !important;
}
.agency-list .app-user-list .bs-stepper .agency-list-card .card_head .download_btn{
    padding: 0;
    background: transparent !important;
    border-color: transparent !important;
    font-size: 1.5rem;
    margin-top: 1.5rem;
}
.agency-list .app-user-list .bs-stepper .agency-list-card .table-responsive.stu_tbl{
    border: 1px solid #e7e7eb;
    border-radius: 0.357rem;
    margin-bottom: 50px;
}
.agency-list .app-user-list .bs-stepper .filter_card{
    border-radius: 0;
    background: #e5e1fe !important;
}
.agency-list .app-user-list .bs-stepper .agency-list-card .card_head .list_ref_badge {
    padding: 3px 8px;
    border-radius: .25rem;
    letter-spacing: .3px;
    display: inline-block;
    font-size: .8rem;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-left: 6px;
}

</style>