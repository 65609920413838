<template>
<section class="user-role">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item active">Bank Account List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Bank Account Information</h3>
                    </div>
                    <div class="col-6 text-right">
                        <!-- <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_right_sidebar_form()">
                            <i data-feather="plus-square"></i>
                            Add New Bank Account
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th><span class="ml-1">#</span></th>
                                <th>bank name</th>
                                <th ><span>beneficiary name</span></th>
                                <th><span>account number</span></th>
                                <th>iban number</th>
                                <th>swift code</th>
                                <th>sort code</th>
                                <th>address</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td><span class="ml-1" v-html="index + 1"></span></td>
                                <td>
                                    <span class="text-dark" v-html="item.bank_name"></span>
                                </td>
                                <td>
                                    <span class="font-weight-bold text-dark" v-html="item.beneficiary_name"></span>
                                </td>
                                <td>
                                    <span class="text-truncate" v-html="item.account_number"></span>
                                </td>
                                <td>
                                    <span v-html="item.iban_number" v-tooltip="'Iban Number'"></span>
                                </td>
                                <td>
                                    <span v-html="item.swift_code" v-tooltip="'Swift Code'"></span>
                                </td>
                                <td>
                                    <span v-html="item.sort_code" v-tooltip="'Sort Code'"></span>
                                </td>
                                <td>
                                    <span v-html="item.address"></span><br/>
                                    <span v-if="item.country" v-html="item.country.name"></span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        
        <!-- <div class="card">
            <div class="card-body">
                <h5 class="mb-1">Bank Account List</h5>
                <div class="row mt-1 bank_account_info" v-if="listData">
                    <div class="col-4" v-for="(bank, index) in listData" :key="index">
                        <div class="mb-2" :class=" bank.verified_at ? 'bank_block_verified' : 'bank_block'">
                        <h6 class="mb-1 text-dark font-weight-bold">
                            <i class="fa fa-university"></i>
                            {{ bank.bank_name }}
                            <i class="fa fa-square-o cursor-pointer ml-1 text-warning" @click="verify_agent_bank_data(bank)"
                            v-if="!bank.verified_at && permission_check('agent_bank_verification')" v-tooltip="'Verify Bank Acount'"></i>
                            <i v-if="bank.verified_at" class="fa fa-check ml-1 text-success" v-tooltip="'Verified at ' + dDate(bank.verified_at)"></i>
                            <small class="badge badge-light-success pull-right" v-if="bank.is_primary">Primary</small>
                            <small @click="make_agent_bank_primary(bank)" class="badge badge-light-info cursor-pointer pull-right" v-else>Make Primary</small>
                        </h6>
                        <p class="card-text" v-html="'Account Name: ' + bank.beneficiary_name"></p>
                        <p class="card-text" v-html="'Account Number: ' + bank.account_number"></p>
                        <p class="card-text">
                            {{ "Iban Number/Sort Code/Swift Code: " + bank.iban_number + " / " + bank.sort_code + " / " + bank.swift_code }}
                        </p>
                        <p class="card-text" v-html="'Address: ' + bank.address"></p>
                        <p class="card-text" v-html="'Country: ' + bank.country.name"></p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span><i class="fa fa-exclamation-triangle text-danger"></i>Bank data not found.</span>
                </div>
            </div>
        </div> -->

        
        <sidebar-form
            :show_sidebar="flag.show_right_sidebar_form"
            v-on:toggle-sidebar="toggle_right_sidebar_form()"
            title="Add New Bank Account"
        >
            
            <div class="card-body">
                <h5 class="card-title text-primary" v-if="!form.bankAccountInfo.id">Add Bank Account</h5>
                <h5 class="card-title text-primary" v-if="form.bankAccountInfo.id">Edit Bank Account</h5>
                <div class="add_permits_form">
                    <div class="form-group">
                        <label for="bank_name"><b>Bank Name</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.bankAccountInfo.bank_name" class="form-control" id="bank_name" placeholder="Enter bank name">
                    </div>
                    <div class="form-group">
                        <label for="beneficiary_name"><b>Beneficiary Name</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.bankAccountInfo.beneficiary_name" class="form-control" id="beneficiary_name" placeholder="Enter beneficiary name">
                    </div>
                    <div class="form-group">
                        <label for="account_number"><b>Account Number</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.bankAccountInfo.account_number" class="form-control" id="account_number" placeholder="Enter account number">
                    </div>
                    <div class="form-group">
                        <label for="iban_number"><b>IBAN Number</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.bankAccountInfo.iban_number" class="form-control" id="iban_number" placeholder="Enter iban number">
                    </div>
                    <div class="form-group">
                        <label for="swift_code"><b>Swift Code</b> <span class="text-danger"></span></label>
                        <input type="text" v-model="form.bankAccountInfo.swift_code" class="form-control" id="swift_code" placeholder="Enter swift code">
                    </div>
                    <div class="form-group">
                        <label for="sort_code"><b>Sort Code</b> <span class="text-danger"></span></label>
                        <input type="text" v-model="form.bankAccountInfo.sort_code" class="form-control" id="sort_code" placeholder="Enter sort code">
                    </div>
                    <div class="form-group">
                        <label for="address"><b>Address</b> <span class="text-danger"></span></label>
                        <input type="text" v-model="form.bankAccountInfo.address" class="form-control" id="address" placeholder="Enter address">
                    </div>
                    <div class="form-group">
                        <label for="country_id"><b>Country</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.bankAccountInfo.country_id" :options="countryList" placeholder="Select country" id="country_id" autocomplete="off"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.bankAccountInfo.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.bankAccountInfo.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
// import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
// import { BFormCheckbox } from 'bootstrap-vue'
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'BankAccountList',
    metaInfo: {
        titleTemplate: 'University Of Sheffield | Bank Account'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        // EditIcon,
        // Trash2Icon,
        // XIcon,
        // BFormCheckbox
    },
    data:()=>{
        return {
            saveData: true,
            flag:{
                show_right_sidebar_form:false
            },
            form: {
                bankAccountInfo: {
                    id: '',
                    bank_name: null,
                    beneficiary_name: null,
                    account_number: '',
                    iban_number: '',
                    swift_code: '',
                    sort_code: '',
                    country_id: null,
                    address: '',
                    is_primary: '',
                    active: '',
                }
            },
            listData: null,
            countryList:[],
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.bankAccountInfo = {}
        },
        update_reset: function () {
            this.form.bankAccountInfo = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_bank_primary_account_informations'));
                this.listData = res.data.list.map(item => {
                    const objData = { 
                        active : item.active == 1 ? true : false,
                        is_primary : item.is_primary == 1 ? true : false,
                    }
                    return Object.assign({}, item, objData)
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/add_bank_account_information'), ref.form.bankAccountInfo);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_bank_account_information'), ref.form.bankAccountInfo);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        updateIsPrimaryStatus: async function (item) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Make Primary Account',
                html: "Do you want to make " + item.bank_name + '-' + item.account_number + " this account as your primary bank account for receiving commissions?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateIsPrimaryStatusConfirmed(item)
                } else {
                    if (item.is_primary) {
                        item.is_primary = false
                    } else {
                        item.is_primary = true
                    }
                }
            })
        },
        updateIsPrimaryStatusConfirmed: async function (item) {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_bank_account_information_is_primary'), item);
                ref.alert(res.msg);
                this.getListData()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        updateActiveStatus: async function (item) {
            
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Active Status Change',
                html: "Do you want to change active status of this bank account?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateActiveStatusConfirmed(item)
                } else {
                    if (item.active) {
                        item.active = false
                    } else {
                        item.active = true
                    }
                }
            })
        },
        updateActiveStatusConfirmed: async function (item) {
            console.log('item', item)

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/active_inactive_bank_account_information'), item);
                ref.alert(res.msg);
                // this.getListData()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_right_sidebar_form()
            this.saveData = false

            this.form.bankAccountInfo = JSON.parse(JSON.stringify(item))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.post(ref.url('api/v1/agent/ajax/delete_bank_account_information'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
    },
    mounted: async function(){
        feather.replace();
        this.getListData();
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        });
    }
}
</script>

<style>
.bank_block {
  /* border: 2px solid #3BAAFF;
    background: #F6FCFF; */
  border: 2px solid #acacac;
  background: #f3f2f7;
  padding: 15px;
  border-radius: 10px;
}
.bank_block_verified {
  border: 2px solid #28c76f;
  padding: 15px;
  border-radius: 10px;
  background: #f6fff8;
}
</style>
