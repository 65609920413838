<template>
<section class="dashboard">
    <main-layout>
        <div class="content-header row">
            <div class="content-header-left col-md-12 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-6">
                        <!-- <h2 class="content-header-title float-left mb-0">Submit List</h2> -->
                        <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">Claim List</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake List</router-link></li>
                                <!-- <li class="breadcrumb-item active">Student List By Intake</li> -->
                                <li class="breadcrumb-item active" v-html="cn(intakeItem, 'name', 'Loading...')"></li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-6 text-right">

                        <span v-if="inDeadline" class="alert p-1" :class="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' ? 'alert-success' : ' alert-danger'">
                            <span v-if="cn(intakeItem, 'end_date', null)" v-html="'Deadline: '+ dDate(intakeItem.end_date) + ' | '"></span><span>This list is not submitted yet</span>
                        </span>
                          
                        <template v-if="sub_agent_status !=1">
                            <button 
                                v-if="studentsCount && intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' && !checkCurrentDateGreaterThenDate(intakeItem.end_date)"
                                @click="submitAllStudent()" class="btn btn-success waves-effect waves-float waves-light ml-1 cust_btn">
                                Submit Your List
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="true">

        <div class="card bg-light-gray mb-1 filter_card" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <!-- <div class="col-md-3">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div> -->
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <!-- <div class="col-md-3">
                        <treeselect v-model="search.submission_status_id" :options="statusTreeSlectList" id="submission_status_id" placeholder="Select status" autocomplete="off"/>
                    </div> -->
                    <div class="col-md-3">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                </div>
                <div class="form-row mt-1">
                    <!-- <div class="col-md-3">
                        <flat-pickr
                        id="edit_end_date"
                        v-model="search.start_date"
                        :config="configs.flatpickr2"
                        class="form-control"
                        placeholder="Select start date"/>
                    </div> -->
                    <!-- <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div> -->
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <div class="form-row d-flex flex-row-reverse">                          
                            <div class="col-md-4">
                                <!-- <button class="btn btn-primary btn-block" @click="searchData()"><i data-feather='search'></i> Search</button> -->
                                <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i>Clear</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary btn-block cust_btn" @click="searchData()">Search</button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <h3>Claim List for {{ intakeItem ? intakeItem.name : 'intakeItem' }}</h3>
                        <!-- <p>This list is not submitted yet</p> -->
                    </div>
                    <div class="col-md-6 text-right" v-show="inDeadline" >

                        <!-- <b-dropdown class="mr-2" text="Export As" variant="outline-primary">
                            <b-dropdown-item download :href="info.download_url" v-if="this.cn(this.$store.state, 'site.auth_user', null)">
                                Excel                                            
                            </b-dropdown-item>
                        </b-dropdown>   -->                      

                        <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light mr-2" :to="`/add-student/${hash_id(this.search.intake_id)}`">
                            <i data-feather="plus-square"></i>
                            Add New Student
                        </router-link> -->
                            <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light mr-1" :to="'/unassigned-students/'+$route.params.id">Add Students</router-link> -->

                            <a type="button" download :href="info.download_url" v-if="this.cn(this.$store.state, 'site.auth_user', null)" class="btn btn-secondary waves-effect waves-float waves-light cust_btn download_btn" v-tooltip="'Export as Excel'"><i class="fa fa-cloud-download"></i></a>

                            <span v-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published'">
                                <router-link v-if="intakeItem.list_info" :to="`/add-student/${$route.params.id}?list_id=${hash_id(intakeItem.list_info.id)}`" class="btn btn-primary waves-effect waves-float waves-light mr-1 cust_btn" ><i class="fa fa-plus mr-1"></i>Add Students</router-link>
                                <router-link v-else :to="'/add-student/'+$route.params.id" class="btn btn-primary waves-effect waves-float waves-light mr-1 cust_btn" ><i class="fa fa-plus mr-1"></i>Add Students</router-link>
                            </span>
                            
                            <!-- <button 
                                v-if="studentsCount && intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published'"
                                @click="submitAllStudent()" class="btn btn-success waves-effect waves-float waves-light mr-1">
                                Submit Your List
                            </button> -->

                        <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light cust_btn"><i class="fa fa-filter mr-1"></i>Search Filter</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive-alert p-0 alert_msg_box" v-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_closed'">
                            <div class="alert alert-danger mt-1 alert-validation-msg">
                                <div class="alert-body">
                                    <p class="text-center"><i class="fa fa-exclamation-circle mr-50"></i>The deadline has passed to submit your commission list. Please contact your International Recruitment Coordinator for further information.</p>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive-alert p-0 alert_msg_box" v-else-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' && checkCurrentDateLessThenDate(intakeItem.start_date)">
                            <div class="alert alert-danger mt-1 alert-validation-msg">
                                <div class="alert-body">
                                    <p class="text-center"><i class="fa fa-exclamation-circle mr-50"></i>You shall be allowed to submit your list for commission between <strong>{{ dDate(intakeItem.start_date) }}</strong> - <strong>{{ dDate(intakeItem.end_date) }}</strong>.</p>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive-alert p-0 alert_msg_box" v-else-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' && checkCurrentDateGreaterThenDate(intakeItem.end_date)">
                            <div class="alert alert-warning mt-1 alert-validation-msg">
                                <div class="alert-body">
                                    <!-- <p class="text-center">You shall be allowed to submit your list for commission between <strong>{{ dDate(intakeItem.start_date) }}</strong> - <strong>{{ dDate(intakeItem.end_date) }}</strong>.</p> -->
                                    <p class="text-center"><i class="fa fa-exclamation-circle mr-50"></i>The deadline has passed to submit your commission list. Please contact your International Recruitment Coordinator for further information.</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="row" v-if="this.editItem">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <sidebar-form 
                            :show_sidebar="flag.show_modal_form"
                            v-on:toggle-sidebar="toggle_modal_form()"
                            title="Edit Student"
                            >
                            <div class="mb-1">
                                <h5 class="">
                                    <span class="align-middle">Edit Student</span>
                                </h5>
                            </div>
                            <div class="flex-grow-1 wait_me_edit_form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">Student ID <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.uni_ref_no" class="form-control" id="edit_intake_name" placeholder="Enter Student ID">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">First / Given Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter First / Given Name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">Last / Family Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter Last / Family Name">
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_end_date">Date of Birth</label>
                                                <flat-pickr
                                                    id="edit_end_date"
                                                    v-model="editItem.dob"
                                                    :config="configs.flatpickr"
                                                    class="form-control"
                                                    placeholder="Select end date"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_level_id">Course Level</label>
                                            <treeselect v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_title">Course Title</label>
                                            <input type="text" v-model="editItem.subject" class="form-control" id="course_title" placeholder="Enter course title">
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="country_id">Country</label>
                                            <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                        <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                                    </div>
                                </div>
                            </div>
                        </sidebar-form>
                    </div>
                </div>

     
                <div class="table-responsive stu_tbl mt-2" v-if="intakeItem.intake_status && intakeItem.intake_status.code == 'intake_status_published' && !checkCurrentDateLessThenDate(intakeItem.start_date) && !checkCurrentDateGreaterThenDate(intakeItem.end_date)">
                    <table class="table line_tbl student_list_tbl">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-center">Agent Code</th>
                                <th class="text-center">Student ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <!-- <th class="text-right">DOB</th> -->
                                <th class="text-center">Nationality</th>
                                <!-- <th class="text-center px-1">Start Date</th> -->
                                <!-- <th class="text-center">Status</th> -->
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="studentList && studentList.length > 0">
                            <tr v-for="(item, index) in studentList" :key="index">
                                <td class="text-center">
                                    <span class="d-inline" v-html="index + pagination.slOffset"></span>
                                </td>
                                <td class="text-center">
                                    <strong v-if="cn(item, 'agent_branch', null)" v-tooltip="'Branch: '+item.agent_branch.branch_name">
                                        {{ item.agent_branch.branch_banner_code }}
                                    </strong>
                                    <strong v-else-if="cn(item, 'agent_company', null)">{{ item.agent_company.agent_code }}</strong>  
                                </td>
                                <td class="text-center">
                                    <strong v-tooltip="'Student ID'" v-html="item.uni_ref_no"></strong>
                                </td>
                                 <td v-html="item.first_name"></td>
                                <td v-html="item.last_name"></td>
                                <!-- <td class="text-right"><span v-if="item.dob" v-html="dDate(item.dob)"></span></td> -->
                                <td class="px-1">
                                    <div class="d-flex justify-content-center align-items-center cursor-pointer">
                                        <div v-if="item.country" @click="editData(item)" class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="item.country.name">
                                                <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td class="px-1">
                                    <span class="text-truncate align-middle cursor-pointer" style="font-size: 13px">
                                        <span v-tooltip="item.subject" v-html="item.subject"></span>
                                    </span>
                                    <br />

                                    <small class="emp_post text-muted" v-html="cn(item, 'course_level.name', '-')"></small>
                                </td>
                                <td class="px-1"> 
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small>
                                </td>
                                <td class="px-1">
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission)"></small><br>
                                    <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.commission_percentage)+'%'"></small><br>
                                </td> -->
                                <!-- <td class="text-center px-1">
                                    <span v-html="dDate(item.intake_month)"></span>
                                </td> -->
                                <!-- <td class="text-center">
                                    <small :class="'badge badge-pill badge-light-' + item.submission_status.color" v-html="item.submission_status.name"></small><br>
                                </td> -->
                                <td class="text-center">

                                    <div class="btn_group">
                                        <div class="action_btn" @click="editData(item)">
                                          <a href="javascript:void(0)" v-tooltip="'Edit'" aria-hidden="true">
                                            <i class="fa fa-pencil"></i>
                                          </a>
                                        </div>

                                        <div class="action_btn" @click="deleteFromIntake(item)">
                                          <a href="javascript:void(0)" v-tooltip="'Delete'">
                                            <i class="fa fa-trash"></i>
                                          </a>
                                        </div>
                                    </div>

                                    <!-- <b-dropdown class="edit_dropdown" variant="'outline-primary'" v-if="item.submission_status.code != 'as_submission_submitted'">
                                        <template #button-content class="hide-arrow">

                                             <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                        </template>

                                        <b-dropdown-item style="" @click="editData(item)" v-if="item.submission_status.color !== 'success'">
                                            Edit
                                        </b-dropdown-item>
                                        <b-dropdown-item style="" @click="deleteFromIntake(item)">
                                            Remove From Intake
                                        </b-dropdown-item>
                                    </b-dropdown> -->

                                    <div class="d-none">
                                        <button type="button" class="btn btn-outline-primary btn-sm" v-tooltip="'Edit'" @click="editData(item)" style="margin-right: 5px"><edit-icon size="1.5x" class="custom-class"></edit-icon></button>

                                        <!-- <button type="button" class="btn btn-outline-danger btn-sm" v-tooltip="'Delete'" @click="deleteFromIntake(item)"><minus-square-icon size="1.5x" class="custom-class"></minus-square-icon></button> -->
                                        <button type="button" class="btn btn-outline-danger btn-sm" v-tooltip="'Delete'" @click="deleteFromIntake(item)">
                                            <i class="fa fa-trash-o font-size-14" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="9" class="text-center no_info_th">
                                    <span v-if="studentList === null" >Processing...</span>
                                    <span v-else class="text-warning">
                                        <i class="fa fa-exclamation-triangle mr-50" aria-hidden="true"></i>
                                        Found no student data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>

            </div>
        </div>

        </template>

    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../SidebarForm';
// import flatPickr from 'vue-flatpickr-component';
// import { EditIcon, MinusSquareIcon } from 'vue-feather-icons';
import { EditIcon } from 'vue-feather-icons';
import Swal from 'sweetalert2'
import Hashids from 'hashids';
import moment from 'moment';

export default{
    name: 'StudentListByIntake',
    components:{
        MainLayout,
        BPagination,
        BDropdown, BDropdownItem,
        Treeselect,
        SidebarForm,
        // flatPickr,
        // MoreVerticalIcon
        EditIcon,
        // MinusSquareIcon
    },
    data:()=>{
        return {
            studentList: null,
            studentsCount: 0,
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            countryList: [],
            inDeadline: true,
            intakeClosed: false,
            intakeItem: {},
            studentListItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                }
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
            },
            list:{
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }]
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            courseTitleList: [],
            info: {
                download_url:'#',
            },
        };
    },
    watch: {
        'search.region_id': function (value) {
            this.countryList = this.getCountryList(value)
            // this.getListData()
        },
        'search.country_id': function () {
            // this.getListData()
        },
        'search.name': function () {
            // this.getListData()
        }
    },
    methods:{
        ClearSearchData () {
            this.search.course_level_id = null
            this.search.country_id = null
            this.search.uni_ref_no = ''
            this.search.name = ''
            this.search.last_name = ''
            this.search.start_date = ''
            this.search.submission_status_id = null
            this.search.intake_id = null
            this.searchData()
        },
        check_all_invitaion: function(check_all=false){

            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){

                if(invitation_from.checked_all){
                   ref.studentList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else  invitation_from.agent_ids=[];

            }else{
                
                if(invitation_from.agent_ids.length==ref.studentList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }

        },
        async searchData () {
            this.getListData()
            this.gen_download_url();
        },
        gen_download_url: function () {
            var ref=this;
            this.search.auth_id = ref.store_auth_user.id
            var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            var institute_id = ref.store_auth_user.institute_id;
            ref.info.download_url = ref.url('download/agent_students_by_intake_export_excel/' + hash_ids.encode(institute_id)+ '?'+ qstr);
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        checkIntakeInDeadline: async function(){
            var ref=this;
            var jq=ref.jq();

            if (this.search.intake_id != null) {
                try {
                    ref.wait_me('.agency-list-card');
                    const params = Object.assign({}, this.search)
                    var res = await jq.get(ref.url('api/v1/agent/ajax/check_in_intake_deadline'), params);
                    ref.intakeItem = res.data.intake
                    ref.intakeClosed = res.data.intakeClosed
                    if (res.data.in_expiry_intake) {
                        this.inDeadline = true
                    } else {
                        this.inDeadline = false
                    }

                } catch (err) {
                    // ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.agency-list-card', 'hide');
                }
            } else {
                this.getListData()
            }
        },
        checkStudentListSubmitted: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_student_list_submitted'), params);
                ref.studentListItem = res.data.data
            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage }, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_student_list'), params);
                if (res.data.studentList.data.length) {
                    this.studentList = res.data.studentList.data
                    // this.studentList = res.data.studentList.data.map(item => {
                    //     const StatusData = Object.assign({}, item.submission_status, JSON.parse(item.submission_status.data))
                    //     return Object.assign({}, item, { submission_status: StatusData })
                    // });
                } else {
                    this.studentList = []
                }
                this.studentsCount = res.data.studentsCount
                this.paginationData(res.data.studentList)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        submitAllStudent: function(){
            // this.$refs.invitation_confirmation.show();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
                // title: 'Agent Student Submission',
                // title: 'Student Submission To ' + this.intakeItem.name + ' List',
                title: 'Do you want to submit this list to the University?',
                html: ' Once submitted, you can no longer add or edit any students.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitAllStudentConfirm()
                }
            })
        },
        submitAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { intake_id: this.search.intake_id })
                var res = await jq.post(ref.url('api/v1/agent/ajax/submit_agent_all_student_uclan_agent'), params);
                this.$router.push('/submit-to-university')
                // this.getListData()
                // this.checkStudentListSubmitted()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_dropdown_list'));
            this.regionList = res.data.regionList.map(item => {
                return {
                    id: item.id,
                    label: item.title
                }
            })
        },
        getRegionCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_countries_list'));
            this.regionCountryList = res.data.regionCountryList
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        deleteFromIntake: function (item) {
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure you want to delete this student? ',
                html: "You can not undo this operation.",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFromConfirm(item)
                }
            })
        },
        deleteFromConfirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/delete_from_intake_agent_student'), item);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.getListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'agent_student_submission_statuses' }
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                // console.log('res.data.statuses', this.statusTreeSlectList)
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        sub_agent_status:function(){
            return this.cn(this.$store.state, 'site.sub_agent_status', null);
        }
    },
    created: async function(){
        this.setup_urls();
        if (!this.$route.params.id) {
            this.$router.push('/submit-to-university')
        } else {
            this.search.intake_id = this.hash_id(this.$route.params.id, false)[0]
            if (!this.search.intake_id) {
                this.$router.push('/submit-to-university')
            }
        }
            
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        
        this.gen_download_url();
    },
    mounted: function(){
        this.checkIntakeInDeadline();
        this.checkStudentListSubmitted();
        this.getListData();
        // this.getRegionList()
        // this.getRegionCountryList()
        this.getCourseLevelList()
        this.getStatusList()
        feather.replace();
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }

    .sams-pay-university .content-wrapper .bg-light-gray.filter_card {
        border-radius: 0;
        background: #e5e1fe !important;
    }
    .sams-pay-university .content-wrapper .cust_btn.btn-primary {
        border-color: rgb(105 102 175) !important;
        background: #6966af !important;
        border-radius: 0.357rem !important;
    }
    .sams-pay-university .content-wrapper .download_btn {
        padding: 0;
        background: transparent !important;
        border-color: transparent !important;
        font-size: 1.5rem;
        margin-right: 1.5rem;
        color: #6966af !important;
    }
    .sams-pay-university .content-wrapper .table-responsive.stu_tbl {
        border: 1px solid #e7e7eb;
        border-radius: 0.357rem;
        margin-bottom: 50px;
    }
    .sams-pay-university .content-wrapper .table-responsive .pagination {
        bottom: 0;
        position: absolute;
        right: 0;
    }
    .sams-pay-university .content-wrapper .table-responsive-alert {
        padding: 0 6px;
        min-height: 275px;
    }
    .sams-pay-university .content-wrapper .table-responsive-alert.alert_msg_box .alert {
        top: 7.75rem !important;
    }
    .sams-pay-university .content-wrapper .table-responsive-alert.alert_msg_box .alert .fa {
        font-size: 1.25rem;
        top: 1px;
    }
</style>
