<template>
<section class="component-data-list">
    <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing" class="change_pass">
                        <div class="row match-height">
                            <div class="col-md-4 col-md-offset-4 m-auto">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title ml-2 mt-2">Change Password</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="old_password">Old password <span class="text-danger">*</span></label>
                                                    <input type="password" v-model="form.user.old_password" class="form-control" id="old_password" placeholder="Enter old password" @keyup.enter="resetPassword()" autocomplete="off"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="password">New password <span class="text-danger">*</span></label>
                                                    <input type="password" v-model="form.user.password" class="form-control" id="password" placeholder="Enter new password" v-on:keyup="password_validation_check()" @keyup.enter="resetPassword()" autocomplete="off"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="confirm_password">Confirm password <span class="text-danger">*</span></label>
                                                    <input type="password" v-model="form.user.confirm_password" class="form-control" id="confirm_password" placeholder="Enter confirm password" v-on:keyup="password_validation_check()" @keyup.enter="resetPassword()" autocomplete="off"/>
                                                </div>
                                                <div class="form-group">
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.character"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.character"></x-icon>
                                                        At least 8 character long.
                                                    </span><br>
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.lowercase"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.lowercase"></x-icon>
                                                        One lowercase character
                                                    </span><br>
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.uppercase"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.uppercase"></x-icon>
                                                        One uppercase character
                                                    </span><br>
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.number"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.number"></x-icon>
                                                        One Number
                                                    </span><br>
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.symbol"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.symbol"></x-icon>
                                                        One special character
                                                    </span><br>
                                                    <span>
                                                        <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.confirm_password"></check-icon>
                                                        <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.confirm_password"></x-icon>
                                                        Confirm password not match.
                                                    </span>
                                                </div>
                                                <div class="form-row mt-2">
                                                    <div class="col text-right">
                                                        <button type="button" class="btn btn-success cust_btn" @click.prevent="resetPassword()">Update Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->


        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'
// import { AlertTriangleIcon } from 'vue-feather-icons'
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default{
    name: 'ChangePassword',
    metaInfo: {
        titleTemplate: 'University Of Sheffield | Change Password'
    },
    components:{
        MainLayout,
        CheckIcon, XIcon
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                user: {
                    name: '',
                    email: '',
                    old_password: '',
                    password: '',
                    confirm_password: '',
                }
            },
            errorMessages: {}
        };
    },
    computed: {
    },
    created: async function () {
        this.setup_urls();
    },
    methods:{
        password_validation_check: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v1/agent/auth/password_validation_check'), ref.form.user);
                ref.errorMessages = res
                // console.log('errorMessages', ref.errorMessages)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        resetPassword: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/reset_user_password_uclan_agent'), ref.form.user);
                localStorage.removeItem('agent_api_token')
                this.$router.push('/dashboard')
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
    }
}
</script>

<style scoped>
    /* .regional-form-section {
        min-height: 100% !important;
    } */

    .change_pass .wait_me_insert_form .cust_btn.btn-success {
        border-color: rgb(105 102 175) !important;
        background: #6966af !important;
    }
    .change_pass .wait_me_insert_form .cust_btn {
        border-radius: 0.357rem !important;
    }

</style>