<template>
<section class="login">
    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mt-0">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('static/sheffield/logo-v2.png')" width="200">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 login_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"> -->
                                <img class="img-fluid" :src="url('static/sheffield/uni-login-banner-v1.jpg')" alt="Agent Logo" />
                            <!-- </div> -->
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <button class="btn btn-outline-primary toast-basic-toggler mt-2" style="display:none">Toast</button>
                                <h2 class="card-title font-weight-bold mb-1">Welcome !</h2>
                                <p class="card-text mb-2">Please Sign-in to the University Of Sheffield Commission Management System</p>
                                <div class="auth-login-form mt-2">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <input class="form-control" type="text" placeholder="Email Address" aria-describedby="login-email" autofocus="" tabindex="1" v-model="form.login.email" v-on:keyup.enter="login()"/>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Password</label>
                                            <router-link  to="/forgot-password">
                                                <small>Forgot Password?</small>
                                            </router-link>

                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.login.password" v-on:keyup.enter="login()"/>
                                            <!-- <div class="input-group-append"><span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span></div> -->
                                        </div>
                                    </div>
<!--                                     <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
                                            <label class="custom-control-label" for="remember-me"> Remember Me</label>
                                        </div>
                                    </div> -->
                                    <button type="button" class="btn btn-primary btn-block cust_btn" tabindex="4" v-on:click="login()">Sign in</button>
                                </div>
<!--                                 <p class="text-center mt-2"><span>New on our platform?</span><a href="a-re.html"><span>&nbsp;Create an account</span></a></p>
                                <div class="divider my-2">
                                    <div class="divider-text">or</div>
                                </div> -->

                            </div>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- END: Content-->
    <div class="toast toast-basic hide position-fixed" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000" style="top: 1rem; right: 1rem">
        <div class="toast-header">
            <!-- <img src="images/logo.png" width="100"> -->
            <!-- <img src="images/logo.png" class="mr-1" alt="Toast image" width="50" /> -->
            <i data-feather='check-circle'  style="color: #28c76f;"></i>
            <strong class="mr-auto" style="color: #28c76f; margin-left: 10px;">Thank you for signup</strong>
            <small class="text-muted">just now</small>
            <button type="button" class="ml-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">Hello, DU! please wait for confirmation email. Hope you're doing well.. :)</div>
    </div>
    <!-- Basic toast ends -->

</section>
</template>

<script>

export default {
    name: 'Login',
    data: function(){
        return {
            form:{
                login:{
                    email:'',
                    password:''
                }
            }
        };
    },
    methods: {
        reset_forms: function(){
            var ref=this;
            ref.form.login.email='';
            ref.form.login.password='';
        },
        login: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/auth/login');
            ref.wait_me('.auth-login-form');

            var form_data=ref.form.login;

            try{

                ref.wait_me('.email-fields', 'hide');
                var res=await jq.post(url, form_data);
                //ref.alert(res.msg);
                ref.reset_forms();

                if(res.data.has_2fa){
                    ref.$router.push(`/login-otp/${res.data.has_2fa}`);
                    ref.alert(res.msg, 'info');
                    return;
                }else{
                    ref.alert(res.msg);
                }

                localStorage.setItem('agent_api_token', res.data.api_token);
                ref.$store.commit('setApiToken', res.data.api_token);
                ref.ajax_setup('agent_api_token');


                ref.$store.commit('siteInfo', {
                    key: 'agent_company',
                    val: res.data.agent_company
                });

                ref.$store.commit('siteInfo', {
                    key: 'agent_company_logo_url',
                    val: this.cn(this.$store.state, 'site.agent_company.logo_url', null)
                });

                ref.$store.commit('siteInfo', {
                    key: 'auth_user',
                    val: res.data.user
                });

                ref.$store.commit('siteInfo', {
                    key: 'countries',
                    val: res.data.countries
                });

                ref.$store.commit('siteInfo', {
                    key: 'course_levels',
                    val: res.data.course_level
                });
                
                ref.$store.commit('siteInfo', {
                    key: 'sub_agent_status',
                    val: res.data.sub_agent_status
                });

                ref.$store.commit('siteInfo', {
                    key: 'agent_branch',
                    val: res.data.agent_branch
                });

                if (res.data.agent_company.signup_setup_completed) {
                    this.$router.replace(sessionStorage.getItem('redirectPath') || '/dashboard');
                    sessionStorage.removeItem('redirectPath');
                }else{
                    ref.$router.push('/account-settings');
                }


            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.auth-login-form', 'hide');
            }

        }
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
    },
    created: function(){
        this.setup_urls();
        localStorage.removeItem('agent_api_token');
    }
}
</script>

<style scoped>
@import "../../static/vuexy/app-assets/css/pages/page-auth.css";

.login_cover_image {
    position: relative;
}

.login_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.login .cust_btn.btn-primary {
    border-color: rgb(105 102 175) !important;
    background: #6966af !important;
    border-radius: 0.357rem !important;
}
.login .auth-wrapper.auth-v2 .brand-logo {
    top: -1rem;
}
</style>
