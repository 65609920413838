<template>
<section class="forgot-password">
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mt-0">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('static/sheffield/logo-v2.png')" width="200">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 login_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"> -->
                                <img class="img-fluid" :src="url('static/sheffield/uni-login-banner-v1.jpg')" alt="Agent Logo" />
                            <!-- </div> -->
                        </div>
                        <!-- /Left Text-->
                        <!-- Forgot password-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5 forgot-password-form">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <h2 class="card-title font-weight-bold mb-1">Set New Password 🔒</h2>
                                <!-- <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p> -->
                                <div class="mt-2">
                                    <div class="form-group">
                                        <label class="form-label" for="forgot-password-email">Email</label>
                                        <input class="form-control"  type="text" name="email" placeholder="john@example.com" aria-describedby="forgot-password-email" v-model="form.new_password.email" autofocus="" tabindex="1"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="forgot-password-email">Password</label>
                                        <input class="form-control" type="password" name="email" aria-describedby="forgot-password-email" v-model="form.new_password.password" autofocus="" tabindex="2" v-on:keyup="password_validation_check()" @keyup.enter="set_new_password()"/>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="forgot-password-email">Confirm Password</label>
                                        <input class="form-control"  type="password" name="email" aria-describedby="forgot-password-email" v-model="form.new_password.confirm_password" autofocus="" tabindex="3" v-on:keyup="password_validation_check()" @keyup.enter="set_new_password()"/>
                                    </div>
                                    <div class="form-group">
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.character"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.character"></x-icon>
                                            At least 8 character long.
                                        </span><br>
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.lowercase"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.lowercase"></x-icon>
                                            One lowercase character
                                        </span><br>
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.uppercase"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.uppercase"></x-icon>
                                            One uppercase character
                                        </span><br>
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.number"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.number"></x-icon>
                                            One Number
                                        </span><br>
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.symbol"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.symbol"></x-icon>
                                            One special character
                                        </span><br>
                                        <span>
                                            <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.confirm_password"></check-icon>
                                            <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.confirm_password"></x-icon>
                                            Confirm password not match.
                                        </span>
                                    </div>
                                    <button class="btn btn-primary btn-block cust_btn" type="button" tabindex="4" v-on:click="set_new_password()">Update Password</button>
                                </div>
                                <p class="text-center mt-2">
                                    <router-link  to="/login">
                                        <i data-feather="chevron-left"></i> Back to login
                                    </router-link>
                                </p>
                            </div>
                        </div>
                        <!-- /Forgot password-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default {
    name: 'forgotPassword',
    components:{
        CheckIcon, XIcon
    },
    data: function(){
        return {
            form:{
                new_password:{
                    email:'',
                    password:'',
                    confirm_password:''
                }
            },
            errorMessages: {}
        };
    },
    methods: {
        password_validation_check: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v1/agent/auth/password_validation_check'), ref.form.new_password);
                ref.errorMessages = res
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        reset_forms: function(){
            var ref=this;
            ref.form.new_password.email='';
            ref.form.new_password.password='';
            ref.form.new_password.confirm_password='';
        },
        set_new_password: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/auth/set_new_password_uclan_agent');
            ref.wait_me('.forgot-password-form');
            ref.form.new_password.password_reset_token = this.$route.params.password_reset_token
            var form_data=ref.form.new_password;

            try{

                var res=await jq.post(url, form_data);
                ref.alert(res.msg);
                ref.reset_forms();
                ref.$router.push('/');

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.forgot-password-form', 'hide');
            }

        }
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
    },
    created: function(){
        this.setup_urls();
        localStorage.removeItem('agent_api_token');
    }
}
</script>

<style scoped>
@import "../../static/vuexy/app-assets/css/pages/page-auth.css";

.login_cover_image {
    position: relative;
}

.login_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.forgot-password .auth-wrapper.auth-v2 .brand-logo {
    top: -1rem;
}
</style>
