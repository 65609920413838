<template>
    <div>
        <div class="card bg-light-gray mb-1 filter_card" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Reference">
                    </div>
                    <div class="col-md-3">
                        <flat-pickr
                        id="edit_end_date"
                        v-model="search.start_date"
                        :config="configs.flatpickr2"
                        class="form-control"
                        placeholder="Select start date"/>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col">
                                <button class="btn btn-primary btn-block cust_btn" @click="searchData()">Search</button>
                            </div>                            
                            <div class="col">
                                <!-- <button class="btn btn-primary btn-block" @click="searchData()"><i data-feather='search'></i> Search</button> -->
                                <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card">
            <div class="card-body">
                <div class="card_head">
                    <div class="row">
                        <div class="col-md-6">
                            <div v-if="!agent_branch" class="row">
                                <div class="col-md-6">
                                    <div class="p-1 border-radius-10 box-shadow-inset">
                                        <div class="d-flex">
                                            <div>
                                                List Ref: <span v-tooltip="'Submission List Ref'" class="badge badge-pill badge-light-success list_ref_badge" v-html="list_info.list_code"></span><br/>
                                                <span v-if="list_info.notify_agent_at" class="light_text mt-50 d-block">
                                                    <i class="fa fa-calendar mr-50"></i> <span v-tooltip="'Confirmed Date'" v-html="dDate(list_info.notify_agent_at)"></span>
                                                </span>
                                                <!-- <span v-tooltip="'Confirmed Date'" v-html="dDate(list_info.cl_accepted_at)"></span> -->
                                            </div>
                                            <div>
                                                <span v-tooltip="'PO Number'" class="badge badge-pill badge-light-blue ml-50" v-html="list_info.po_number"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">

                            <!-- <b-dropdown class="mr-2" text="Export As" variant="outline-primary">
                                <b-dropdown-item download :href="info.download_url">
                                    Excel                                            
                                </b-dropdown-item>
                            </b-dropdown> -->

                            <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light cust_btn"><i class="fa fa-filter mr-1"></i>Search Filter</button>

                            <!-- <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light"><i data-feather='filter'></i></button> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="mt-2 mb-0">Disputed Student List</h3>
                        </div>
                        <div class="col-md-6 text-right">
                            
                        </div>
                    </div>
                </div>
                
                <div class="row" v-if="this.editItem">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <sidebar-form 
                            :show_sidebar="flag.show_modal_form"
                            v-on:toggle-sidebar="toggle_modal_form()"
                            title="Edit Student"
                            >
                            <div class="mb-1">
                                <h5 class="">
                                    <span class="align-middle">Edit Student</span>
                                </h5>
                            </div>
                            <div class="flex-grow-1 wait_me_edit_form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">First/Given Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_intake_name">Last/Family Name <span class="text-danger">*</span></label>
                                            <input type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="edit_end_date">Date of Birth</label>
                                                <flat-pickr
                                                    id="edit_end_date"
                                                    v-model="editItem.dob"
                                                    :config="configs.flatpickr"
                                                    class="form-control"
                                                    placeholder="Select end date"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_level_id">Course Level</label>
                                            <treeselect v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="course_title">Course Title</label>
                                            <input type="text" v-model="editItem.subject" class="form-control" id="course_title" placeholder="Enter course title">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="country_id">Country</label>
                                            <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                        <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                                    </div>
                                </div>
                            </div>
                        </sidebar-form>
                    </div>
                </div>

     

                <div class="table-responsive stu_tbl">
                    <table class="table line_tbl student_list_tbl">
                        <thead>
                            <tr>
                                <th class="text-center" width="3%">#</th>
                                <th class="text-center" width="10%">Agent Code</th>
                                <th width="20%">Student Details</th>
                                <th width="15%">COURSE Details</th>
                                <th width="10%" class="text-right">Tuition Fees</th>  
                                <th width="5%" class="text-right">Commission</th>
                                <th width="10%" class="text-center">Start Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="studentList && studentList.length > 0">
                            <tr v-for="(item, index) in studentList" :key="index">
                                <td class="text-center">
                                    <span class="d-inline" v-html="index + pagination.slOffset"></span>
                                </td>
                                <td class="text-center">
                                    <strong v-if="cn(item, 'agent_branch', null)" v-tooltip="'Branch: '+item.agent_branch.branch_name">
                                        {{ item.agent_branch.branch_banner_code }}
                                    </strong>
                                    <strong v-else-if="cn(item, 'agent_company', null)">{{ item.agent_company.agent_code }}</strong> 
                                </td>
                                <td>
                                    <div class="d-flex justify-content-left align-items-center cursor-pointer">
                                        <div v-if="item.country" class="avatar-wrapper">
                                            <div class="avatar  mr-50" v-tooltip="item.country.name" style="top: -6px;">
                                                <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="text-dark">
                                                <span class="font-weight-bold" style="font-size: 12px" v-html="item.first_name + ' ' + item.last_name">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-check"
                                                >
                                                    <polyline points="20 6 9 17 4 12"></polyline></svg></span>
                                            </div>
                                            <small class="emp_post text-muted" >
                                                <span v-if="item.dob" v-html="dDate(item.dob)"></span>
                                                 <!-- ★
                                                <span v-html="item.funding_source"></span> -->
                                            </small>
                                            <small class="text-truncate align-middle cursor-pointer mt-25">
                                                <span style="font-size: 100%" class="badge badge-pill badge-light-primary cust_badge">
                                                    <strong v-tooltip="'Student ID'" v-html="item.uni_ref_no"></strong>
                                                    <!-- <i v-if="item.duplicate_exists && item.dispute_status > 0 && item.dispute_status < 11 && ss_counter.cl_status" @click="gotoDisputeListPage()" class="fa fa-exclamation-triangle ml-25 text-warning" aria-hidden="true" v-tooltip="'Dispute Case Exists'"></i> -->
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <!-- <span class="text-truncate align-middle cursor-pointer" style="font-size: 13px">
                                        <span v-tooltip="item.subject" v-html="item.subject"></span>
                                    </span> -->
                                    <span v-if="item.programme" class="text-truncate align-middle cursor-pointer d-block" style="font-size: 13px">
                                        <span v-tooltip="item.programme.name" v-html="item.programme.name"></span>
                                    </span>

                                    <small class="emp_post text-muted" v-html="cn(item, 'course_level.name', '-')"></small>
                                </td>
                                <td class="text-right"> 
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small>
                                </td>
                                <td class="text-right">
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission)"></small><br>
                                    <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.commission_percentage)+'%'"></small><br>
                                </td>
                                <td class="text-center">
                                    <span v-html="dDate(item.intake_month)"></span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center no_info_th">
                                    <span v-if="studentList === null" >Processing...</span>
                                    <span v-else class="text-warning">
                                        <i class="fa fa-exclamation-triangle mr-50" aria-hidden="true"></i>
                                        Found no student data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>

        <!-- Remarks here -->
        <student-remarks :list_id="search.list_id"/>
    </div>
</template>

<script>

import feather from 'feather-icons';
// import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { BPagination } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../SidebarForm';
import flatPickr from 'vue-flatpickr-component';
// import { EditIcon, MinusSquareIcon } from 'vue-feather-icons';
import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Hashids from 'hashids';
import moment from 'moment';
import StudentRemarks from './Partials/StudentRemarks.vue';

export default{
    name: 'DisputedStudentList',
    props: ['list_info', 'ss_counter'],
    components:{
        BPagination,
        // BDropdown, BDropdownItem,
        Treeselect,
        SidebarForm,
        flatPickr,
        StudentRemarks,
        // MoreVerticalIcon
        // EditIcon,
        // MinusSquareIcon
    },
    data:()=>{
        return {
            studentList: null,
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            countryList: [],
            agentAcceptStatusList: [],
            inDeadline: true,
            disabled: false,
            intakeItem: {},
            studentListItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                }
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                intake_id: null,
                list_id: null,
            },
            list:{
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }]
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            courseTitleList: [],
            info: {
                download_url:'#',
            },
        };
    },
    watch: {
        'search.region_id': function (value) {
            this.countryList = this.getCountryList(value)
            // this.getListData()
        },
        'search.country_id': function () {
            // this.getListData()
        },
        'search.name': function () {
            // this.getListData()
        }
    },
    methods:{
        getBackgroundColor: function(item){

            if (item.finance_office_status != 60 && item.cl_status) {
                // return 'bg-light-warning'
                return ''
            } else {
                return ''
            }

        },
        agt_accept_status_color: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });

            if (!status) {
                return 'secondary'
            } else {
                return status.color
            }

        },
        agt_accept_status_name: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });
            // console.log('row', row)

            if (!status) {
                return '-'
            } else {
                return status.name
            }

        },
        agentAcceptStatusUpdate: function(item, dropdown){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            let level = 'Please specify reason';
            let placeholder = 'Enter your specify reason';
            let html_input = '<div class="form-group"><label for="usr">'+ level +':</label><input id="swal_input" type="text" class="form-control" placeholder="'+placeholder+'"></div>';

            swalWithBootstrapButtons.fire({
                title: 'Agent Accept Status!',
                html: html_input,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                preConfirm: () => {
                    return [
                        document.getElementById('swal_input').value
                    ]
                }
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
     
                    this.agentAcceptStatusUpdateConfirm(item, dropdown, result.value[0])

                }
            })
        },
        agentAcceptStatusUpdateConfirm: async function(item, dropdown, swal_input){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, item, { swal_input: swal_input, agent_accept_status: dropdown.id, code: dropdown.code})
                var res = await jq.post(ref.url('api/v1/agent/ajax/agent_student_agent_accept_status_update'), params);
                this.getListData();
                swal("Agent Accept Status Updated", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        ClearSearchData () {
            this.search.course_level_id = null
            this.search.country_id = null
            this.search.uni_ref_no = ''
            this.search.name = ''
            this.search.last_name = ''
            this.search.start_date = ''
            this.search.submission_status_id = null
            this.search.intake_id = null
            this.searchData()
        },
        check_all_invitaion: function(check_all=false){

            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){

                if(invitation_from.checked_all){
                   ref.studentList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else  invitation_from.agent_ids=[];

            }else{
                
                if(invitation_from.agent_ids.length==ref.studentList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }

        },
        async searchData () {
            this.getListData()
            this.gen_download_url();
        },
        gen_download_url: function () {
            var ref=this;
            this.search.auth_id = ref.store_auth_user.id
            var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            ref.info.download_url = ref.url('download/agent_students_export_excel/' + hash_ids.encode(ref.store_auth_user.institute_id)+ '?'+ qstr);
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        getIntakeById: async function(){
            var ref=this;
            var jq=ref.jq();

            if (this.search.intake_id != null) {
                try {
                    ref.wait_me('.agency-list-card');
                    const params = Object.assign({}, this.search)
                    var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                    ref.intakeItem = res.data.intake

                } catch (err) {
                    // ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.agency-list-card', 'hide');
                }
            } else {
                this.getListData()
            }
        },
        checkStudentListSubmitted: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_student_list_submitted'), params);
                ref.studentListItem = res.data.data
            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage, added_to_intake_at: 1 },  this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_disputed_agent_student_list_by_list_id'), params);
                if(res.data.studentList){
                    if (res.data.studentList.data.length) {
                        this.studentList = res.data.studentList.data.map(item => {
                            let submissionStatusData = {}
                            let financialStatusData = {}
                            if (item.submission_status) {
                                submissionStatusData = Object.assign({}, item.submission_status, JSON.parse(item.submission_status.data))
                            }
                            if (item.finance_office_status_data) {
                                financialStatusData = Object.assign({}, item.finance_office_status_data, JSON.parse(item.finance_office_status_data.data))
                            }
                            return Object.assign({}, item, { submission_status: submissionStatusData, financial_office_status: financialStatusData })
                        });
                    } else {
                        this.studentList = []
                    }

                }

                this.paginationData(res.data.studentList)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        submitAllStudent: function(){
            // this.$refs.invitation_confirmation.show();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                // title: 'Agent Student Submission',
                title: 'Student Submission To ' + this.intakeItem.name + ' List',
                html: "Do you want to submit the students to this list?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitAllStudentConfirm()
                }
            })
        },
        submitAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { intake_id: this.search.intake_id })
                var res = await jq.post(ref.url('api/v1/agent/ajax/submit_agent_all_student'), params);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        deleteFromIntake: function (item) {
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Remove From Intake',
                html: "Are you sure do you want to add to intake?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFromConfirm(item)
                }
            })
        },
        deleteFromConfirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/delete_from_intake_agent_student'), item);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.getListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'agent_student_submission_statuses' }
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                // console.log('res.data.statuses', this.statusTreeSlectList)
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        agent_branch: function(){
            return this.cn(this.$store.state, 'site.agent_branch', null);
        }
    },
    created: async function(){
        this.setup_urls();
        
        this.search.list_id = this.hash_id(this.$route.params.list_id, false)[0]
        this.search.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
            
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        
        this.gen_download_url();
    },
    mounted: function(){
        this.getIntakeById();
        // this.checkStudentListSubmitted();
        this.getListData();
        // this.getRegionList()
        // this.getRegionCountryList()
        this.getCourseLevelList()
        this.getStatusList()
        feather.replace();
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
</style>
