<template>
<section class="region-list">
    <main-layout>
        <!-- <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Submit List</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake List</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/student-list">Student List</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/add-student">Add Student</router-link></li>
                                <li class="breadcrumb-item active">Import Students</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Manage Students</h2> -->
                        <!-- <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">Add Group of Students</h5> -->
                        <h5 class="content-header-title float-left mb-0" style="margin-top: 5px">Claim List</h5>
                        <div class="breadcrumb-wrapper">
                            <!-- <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/student-list">Student List</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/add-student">Add Student</router-link></li>
                                <li class="breadcrumb-item active">Import Excel</li>
                            </ol> -->
                            
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/submit-to-university">Intake List</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="'/student-list/'+$route.params.intake_id">{{ cn(intakeItem, 'name', 'Loading...') }}</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="'/student-list/'+$route.params.intake_id">Student List</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="'/add-student/'+ $route.params.intake_id">Add Student</router-link></li>
                                <li class="breadcrumb-item active">Import Excel</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrumb-right">
                    <router-link :to="'/add-student/'+ $route.params.intake_id" class="dt-button btn btn-primary btn-add-record cust_btn">Add Student Manually</router-link>
                </div>
            </div>
        </div>
                
        <div class="row" v-if="this.editItem">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <sidebar-form 
                    :show_sidebar="show_modal_form"
                    v-on:toggle-sidebar="toggle_modal_form()"
                    title="Edit Student"
                    >
                    <div class="mb-1">
                        <h5 class="">
                            <span class="align-middle">Edit Student</span>
                        </h5>
                    </div>
                    <div class="flex-grow-1 wait_me_edit_form">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="uni_ref_no"><b>Student ID</b> <span class="text-danger">*</span></label>
                                    <input type="text" v-model="editItem.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Enter Student ID">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_intake_name"><b>First Name</b> <span class="text-danger">*</span></label>
                                    <input type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_intake_name"><b>Last Name</b> <span class="text-danger">*</span></label>
                                    <input type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_end_date"><b>Date of Birth</b> <span class="text-danger"></span></label>
                                        <flat-pickr
                                            id="edit_end_date"
                                            v-model="editItem.dob"
                                            :config="configs.flatpickr"
                                            class="form-control"
                                            placeholder="Select end date"/>
                                </div>
                            </div> -->

                            <!-- <div class="col-12">
                                <label for="intake_id"><b>Intake Month</b><span class="text-danger">*</span></label>
                                <div class="form-row">
                                    <div class="col-6 form-group">
                                        <treeselect v-model="editItem.intake_month" :options="list.months" placeholder="Select Month" id="intake_month" autocomplete="off"/>
                                    </div>
                                    <div class="col-6 form-group">
                                        <treeselect v-model="editItem.intake_year" :options="list.years" placeholder="Select Year" id="intake_year" autocomplete="off"/>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="intake_id">Intake <span class="text-danger">*</span></label>
                                    <treeselect v-model="editItem.intake_id" :options="intakeList" placeholder="Select Intake" id="intake_id" autocomplete="off"/>
                                </div>
                            </div> -->

                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="course_level_id"><b>Course Level</b> <span class="text-danger">*</span></label>
                                    <treeselect v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                </div>
                            </div> -->
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="course_title"><b>Course Title</b> <span class="text-danger"></span></label>
                                    <input type="text" v-model="editItem.subject" class="form-control" id="course_title" placeholder="Enter course title">
                                </div>
                            </div> -->
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="country_id"><b>Country</b> <span class="text-danger"></span></label>
                                    <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="funding_source"><b>Funding Source</b> <span class="text-danger">*</span></label>
                                    <treeselect v-model="editItem.funding_source" :options="fundingSourceList" placeholder="Select Funding Source" id="funding_source" autocomplete="off"/>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                            </div>
                        </div>
                    </div>
                </sidebar-form>
            </div>
        </div>

            <!-- <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="content-header-title">Import Students</h2>
                        </div>
                    </div>
                </div>
                 <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                    <div class="form-group breadcrumb-right">
                        <router-link to="/add-student" class="dt-button btn btn-primary btn-add-record mr-1">Add Student Manually</router-link>
                    </div>
                </div>
            </div> -->
            <div class="content-body">
                 <!-- Dropzone section start -->
                <section id="dropzone-examples"> 

                    <div class="row">
                        <div class="col-12">
                            <div class="card" v-show="!excelFileImported">
                                <div class="card-header">
                                    <h4 class="card-title">EXCEL File Upload For Multiple Student Add</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-6 guideline_div">
                                            <div class="row">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h4 class="card-title m-0">Guidelines:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <p class="card-text">1.<a :href="url('static/agent_student_upload_file_sample_v3.xlsx')"> Click here to download the sample excel file</a>.</p>
                                                        <p class="card-text">2. Please don't remove the header section in the excel.</p>
                                                        <p class="card-text">3. The example data is dummy for your understanding. This is just to guide you how to fill up the form. Please remove that example data.</p>
                                                        <p class="card-text">4. Please keep the date format as it is in the sample data.</p>
                                                        <p class="card-text">5. Fill up the file</p>
                                                        <p class="card-text">6. Reupload the completed file</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <button @click="clearDropzone()" id="clear-dropzone" class="btn btn-outline-primary mb-1">
                                                <i data-feather="trash" class="mr-25"></i>
                                                <span class="align-middle">Clear Dropzone</span>
                                            </button>
                                            <vue-dropzone 
                                                ref="agent_list_upload_dropzone"
                                                class="dropzone dropzone-area"
                                                id="dpz-remove-all-thumb"
                                                @vdropzone-success="vsuccess"
                                                @vdropzone-error="verror"
                                                :uploadMultiple=false
                                                :duplicateCheck=false
                                                :useCustomSlot=true
                                                :options="dropzone_configs"
                                                v-on:vdropzone-sending="sendingEvent"
                                                >
                                                <div class="dropzone-custom-content">
                                                    <h1 class="dropzone-custom-title text-primary">Click to upload.</h1>
                                                    <h2 class="dropzone-custom-title text-primary">Maximum 200 rows</h2>
                                                </div>
                                            </vue-dropzone>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="card wait_me_to_process" v-show="excelFileImported">
                                <!-- <div class="card-header">
                                    <h4 class="card-title">Email already exist</h4>
                                </div> -->
                                
                                <div class="card-header">
                                    <h4 class="card-title">Imported Data Preview</h4>
                                </div>
                                <div class="card-body">
                                    <!-- <div v-for="(item, index) in importedList" :key="index">
                                        <p><span class="badge badge-dark" v-if="item.duplicate_item == 1">{{ item.email }}</span></p>
                                    </div> -->
                                    <button @click="excelFileImported = false" class="btn btn-primary mb-2 pull-right">Back To New Upload</button>
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Student ID</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <!-- <th>DOB</th> -->
                                                    <th>Nationality</th>
                                                    <!-- <th>Subject Name</th> -->
                                                    <!-- <th>Course Level</th> -->
                                                    <!-- <th class="text-center">Intake Month</th> -->
                                                    <!-- <th>Tuition Fee</th>
                                                    <th class="text-center">Fuding Source</th> -->
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in importedList" :key="index">
                                                    <td v-html="index + 1"></td>
                                                    <td>
                                                        <span v-tooltip="'Student ID'" v-html="item.uni_ref_no" class="mr-1" :class="getDuplicateMark(item) ? 'text-danger': ''"></span>
                                                        <i v-if="item.duplicate == 1" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Duplicate entry'"></i>
                                                        <i v-if="item.uni_ref_no_empty" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Student ID empty'"></i>
                                                        <i v-if="item.invalid_student_id" class="fa fa-exclamation-triangle text-danger" v-tooltip="'Invalid Student ID Format'"></i>
                                                    </td>
                                                    <td v-html="item.first_name"></td>
                                                    <td v-html="item.last_name"></td>
                                                    <!-- <td>
                                                        <span v-html="item.email"></span>
                                                        <i v-if="item.duplicate_item == 1" title="This email already exist!" class="fa fa-clone text-danger ml-1" aria-hidden="true"></i>
                                                    </td> -->
                                                    <!-- <td><span v-if="item.dob" v-html="dDate(item.dob)"></span></td> -->
                                                    <td v-html="item.nationality"></td>
                                                    <!-- <td v-html="item.subject"></td> -->
                                                    <!-- <td v-html="item.course_level"></td> -->
                                                    <!-- <td v-html="dDate(item.intake_month, 'MMM  YYYY')"></td> -->
                                                    <!-- <td class="text-center" v-html="dDate(item.intake_month+'/01/'+item.intake_year, 'MMM  YYYY')"></td> -->
                                                    <!-- <td v-html="item.tuition_fee"></td>
                                                    <td v-html="item.funding_source"></td> -->
                                                    <td>
                                                        <button @click="editData(item)" class="btn btn-outline-info btn-sm mr-1">
                                                            <i v-tooltip="'Edit'" class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button @click="deleteItem(index, item)" class="btn btn-outline-danger btn-sm">
                                                            <i v-tooltip="'Delete'" class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button @click="processToUploadData(importedList)" class="btn btn-success mb-2 pull-right">Process To Upload Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                </section> 
            </div>    
    </main-layout>
</section>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
import swal from 'bootstrap-sweetalert';
import SidebarForm from '../SidebarForm';
// import flatPickr from 'vue-flatpickr-component';
import Treeselect from '@riophae/vue-treeselect';
import Swal from 'sweetalert2';
import moment from 'moment';

export default{
    name: 'AddNewMultipleStudent',
    components:{
        MainLayout,
        vueDropzone: vue2Dropzone,
        SidebarForm,
        // flatPickr,
        Treeselect
    },
    data:()=>{
        return {
            saveData: true,
            excelFileImported: false,
            success: false,
            importedList: [],
            dropzone_configs: {},
            intake_id: '',
            show_modal_form: false,
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            courseLevelList: [],
            intakeList:[],
            intakeDataList:[],
            duplicateStudentIds:[],
            list:{
                months:[],
                years:[]
            },
            intakeItem: {}
        };
    },
    created () {
        this.setup_urls();
        this.dropzoneOptions()
        this.init_data();
        this.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
        this.getIntakeById()
    },
    methods:{
        getDuplicateMark: function (item) {
            const itemObj = this.duplicateStudentIds.find(uni_ref_no => uni_ref_no == item.uni_ref_no)
            if (itemObj) {
                return true
            } else {
                return false
            }
        },
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/agent/ajax/mulitiple_student_inport_init_data');

            try{

                var res=await jq.get(url);
                ref.list.months=res.data.months;
                ref.list.years=res.data.years;

            }catch(err){

                ref.alert(ref.err_msg(err), 'error');

            }

        },
        dropzoneOptions: function () {
            var ref = this;
            ref.dropzone_configs = {
                url: ref.url('api/v1/agent/ajax/uclan_mulitiple_agent_student_excel_file_import'),
                thumbnailWidth: 200,
                addRemoveLinks: true,
                multipleUpload: false,
                maxFiles: 1,
                acceptedFiles: '.xls,.xlsx',
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('agent_api_token'),
                    'Access-Control-Allow-Origin': '*'
                }
            }
        },
        vsuccess: function (file, response) {
            // swal(response.msg, "", "success")

            this.importedList = response.data.imported_list.map((item, index)=>{

                if (item.duplicate) {
                    this.duplicateStudentIds.push(item.uni_ref_no);
                }

                const mapData = {
                    index: index,
                    uni_ref_no_empty: item.uni_ref_no ? false : true
                }
                return Object.assign({}, item, mapData);

            });

            this.$refs.agent_list_upload_dropzone.removeAllFiles();
            this.excelFileImported = true;

        },
        verror: function (file, response) {
            swal(response.msg, "", "danger")
        },
        clearDropzone: function () {
            this.$refs.agent_list_upload_dropzone.removeAllFiles();
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_uni_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getIntakeList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_company_university_intake_list'));
                this.intakeList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                this.intakeDataList = res.data.list
                // console.log('this.intakeDataList', this.intakeDataList)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        deleteItem: function (index, item) {
            swal({
                title: "Are you sure?",
                text: "Press Yes to confirm or Cancel",
                type: "warning",
                showCancelButton: true,
                cancelButtonClass: "btn-light",
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            }, (isConfirm)=>{

                if(!isConfirm) return;
                const duplicateStudentIndex = this.duplicateStudentIds.indexOf(item.uni_ref_no)
                this.duplicateStudentIds.splice(duplicateStudentIndex, 1);
                this.importedList.splice(index, 1);

            });
        },
        toggle_modal_form: function(){
            this.show_modal_form = !this.show_modal_form;
        },
        editData: function (item) {
            // console.log(item);
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.show_modal_form = !this.show_modal_form;
        },
        updateFormData: async function () {

            var ref=this;

            let duplicate_uni_ref=ref.importedList.find(item=>{

                let new_data=ref.editItem //ref.editItem.uni_ref_no;
                
                if(
                    new_data.uni_ref_no==item.uni_ref_no
                    && (
                        new_data.first_name!=item.first_name
                        && new_data.last_name!=item.last_name
                    )
                ) return true;
                else return false;

            });

            //let duplicate_uni_ref=ref.importedList.find(item=> item.duplicate ==1);

            if(duplicate_uni_ref){
                ref.alert(`Please remove duplicate uni ref '${ref.editItem.uni_ref_no}'`, 'error');
                return;
            }
            
            var jq=ref.jq();
            let url=ref.url('api/v1/agent/ajax/agent_student_duplicate_check');

            this.editItem.intake_id=this.hash_id(this.$route.params.intake_id, false)[0];
            
            try{
                ref.wait_me('.wait_me_to_process');
                var res = await jq.get(url, this.editItem);

                const importedListCopy = this.importedList.map(item => item.index === this.editItem.index ? {...this.importedList, ...this.editItem} : item );
                this.importedList = importedListCopy
                
                /* const intakeObj = this.intakeDataList.find(data => data.id == this.editItem.intake_id) */

                const objIndex = this.importedList.findIndex((item => item.index === this.editItem.index))

                //this.importedList[objIndex].intake_month = intakeObj.intake_month

                //this.importedList[objIndex].intake_month = intakeObj.intake_month;

                this.importedList[objIndex].duplicate = res.data.duplicate;
                this.importedList[objIndex].nationality = res.data.nationality;
                // this.importedList[objIndex].course_level = res.data.course_level
                this.importedList[objIndex].domicile = res.data.domicile;
                this.importedList[objIndex].invalid_student_id = res.data.invalid_student_id;

                this.show_modal_form = !this.show_modal_form;
                
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Data updated successfully!',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_to_process', 'hide');
            }
        },
        processToUploadData: async function (data) {

            var ref=this;
            var jq=ref.jq();

            // const countryCheck = data.filter(item => item.country_id == null)
            // if (countryCheck.length) {
            //     ref.alert('Student nationality cannot be empty', 'error')
            //     return;
            // }

            const duplicate = data.find(item => item.duplicate == 1)
            const uni_ref_no_empty = data.find(item => item.uni_ref_no_empty == true);
            
            let has_invalid_id=data.find(item => item.invalid_student_id == true);

            if(has_invalid_id){
                ref.alert('Please remove or edit all invalid student id.', 'error');
                return ;
            }

            if (duplicate != undefined) {
                ref.alert('Please remove or edit duplicate entry before process', 'error')
                return ;
            }
            
            if (uni_ref_no_empty) {
                ref.alert('Student ID cannot be empty', 'error')
                return ;
            } else {
                try{
                    var formData = {
                        agent_students: data,
                        intake_id: this.intake_id,
                    }
                    ref.wait_me('.wait_me_to_process');
                    var res = await jq.post(ref.url('api/v1/agent/ajax/uclan_mulitiple_agent_student_store'), formData);
                    ref.alert(res.msg);
                    if (this.$route.query.list_id) {
                        this.$router.push({ name: 'IntakeWiseStudentList', params: { list_id:this.$route.query.list_id, intake_id: this.$route.params.intake_id } })
                    } else {
                        this.$router.push({ name: 'StudentListByIntake', params: { id: this.$route.params.intake_id } })
                    }
                    this.excelFileImported = false

                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.wait_me_to_process', 'hide');
                }
            }
        },
        getIntakeById: async function(){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({intake_id: this.intake_id})
                var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                ref.intakeItem = res.data.intake

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        sendingEvent: function(file, xhr, formData) {
            formData.append('intake_id', this.hash_id(this.$route.params.intake_id, false)[0]);
        }
    },
    mounted: async function(){
        feather.replace();
        this.getCourseLevelList()
        this.getIntakeList()
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        });
    }
}
</script>

<style>
    @import '../../static/vuexy/app-assets/vendors/css/file-uploaders/dropzone.min.css';
    @import '../../static/vuexy/app-assets/css/plugins/forms/form-file-uploader.css';
</style>